<template>
  <transition name="modal">
    <div v-if="isOpen" class="modal__mask">
      <div class="modal__container">
        <header v-if="hasHeader" class="modal__header">
          <h4>
            <slot name="title"></slot>
          </h4>

          <custom-button type="button" variant="text" @click="close">
            <font-awesome-icon icon="times" size="lg" />
          </custom-button>
        </header>

        <div class="modal__body">
          <p v-if="$slots.description" class="modal__description">
            <slot name="description" />
          </p>

          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CustomButton from './CustomButton.vue';

export default {
  name: 'Modal',

  components: { CustomButton },

  props: {
    hasHeader: {
      default: true,
      type: Boolean,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.modal__mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal__container {
  position: relative;
  max-width: 480px;
  width: 90%;
  max-height: 95%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--light-gray);
  border-radius: var(--lengthSm1);
  transition: all 0.3s ease;
  overflow-y: hidden;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--lengthMd1);
  border-bottom: 1px solid var(--grayColor2);
}

.modal__body {
  flex-grow: 1;
  padding: var(--lengthLg1) var(--lengthMd1);
  overflow-y: auto;
}

.modal__description {
  font-weight: var(--medium);
}

.modal__title,
.modal__icon {
  margin-bottom: var(--lengthMd3);
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 300ms;
}

.modal-enter-to {
  opacity: 1;
}
</style>
