<template>
  <aside v-if="isSidebarOpen" class="sidebar">
    <div class="top-bar">
      <h3>Preguntas</h3>

      <icon-button icon="times" label="Cerrar" @click="closeSidebar" />
    </div>

    <button
      v-for="(question, index) in surveyForm"
      :key="index"
      @click="setStep(index)"
      :disabled="(typeof question.answer ==='object'
        ? !question.answer.id
        : !question.answer) && currentStep < index
      "
      class="shortcut"
      type="button"
      :class="{ 'shortcut--active': currentStep === index }"
    >
      <span class="index">{{ `${index + 1}.` }}</span>
      <span class="question">{{ question.question.text }}</span>
      <span class="answer">
        {{ (typeof question.answer ==='object' ? question.answer.text : question.answer) || '-' }}
      </span>

      <span
        class="check"
        :class="{
          'check--green': typeof question.answer === 'object' ? question.answer.id : question.answer
        }"
      >
        <font-awesome-icon icon="check" size="lg" />
      </span>
    </button>
  </aside>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import IconButton from './IconButton.vue';

export default {
  name: 'Sidebar',

  components: { IconButton },

  computed: {
    ...mapState(['surveyForm', 'currentStep', 'isSidebarOpen']),
  },

  methods: {
    ...mapMutations(['setStep', 'closeSidebar']),
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 var(--lengthMd1) var(--lengthMd1);
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9998;

  @media only screen and (min-width: 768px) {
    position: static;
    max-width: 300px;
  }
}

.top-bar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--lengthMd3) 0;
  background-color: white;
  z-index: 996;
}

.shortcut {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: var(--lengthSm3);
  column-gap: var(--lengthSm3);
  grid-row-gap: var(--lengthSm2);
  row-gap: var(--lengthSm2);
  padding: var(--lengthSm3);
  background-color: var(--grayColor1);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
  overflow-x: hidden;
  transition: transform 300ms;

  & + & {
    margin-top: var(--lengthMd1);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled):not(.shortcut--active) {
    border-color: var(--mainColor1);
  }

  &--active {
    color: white;
    background-color: var(--mainColor1);
    border-color: var(--mainColor1);
  }

  span {
    width: 100%;
    text-align: start;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .question {
    color: var(--fontColor2);
  }

  .answer {
    font-weight: var(--medium);
  }

  .index {
    grid-row: 1 / -1;
    color: var(--fontColor2);
    font-size: var(--lengthMd1);
  }

  .check {
    grid-column: -2 / -1;
    grid-row: 1 / -1;
    color: var(--grayColor3);

    &--green {
      color: var(--successColor);
    }
  }

  &--active .question,
  &--active .answer,
  &--active .index,
  &--active .check {
    color: white;
  }
}

.close-btn {
  color: var(--fontColor3);
  transition: color 150ms;

  &:hover {
    color: var(--fontColor1);
  }
}
</style>
