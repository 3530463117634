<template>
  <div v-if="loading" class="loading-mask">
    <loading-spinner />
  </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'LoadingMask',

  props: {
    loading: Boolean,
  },

  components: { LoadingSpinner },
};
</script>

<style lang="scss" scoped>
.loading-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
  background-color: rgba($color: #fff, $alpha: .5);
  border-radius: inherit;
}
</style>
