<template>
  <button type="button" :title="label" @click="$emit('click', $event)" class="btn">
    <font-awesome-icon :icon="icon" size="lg" />
  </button>
</template>

<script>
export default {
  name: 'IconButton',

  props: {
    label: {
      required: true,
      type: String,
    },

    icon: {
      required: true,
      type: [Object, String],
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: var(--lengthLg1);
  height: var(--lengthLg1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--fontColor2);
  border-radius: 2px;
  transition: color 150ms, background-color 150ms;

  &:hover {
    color: var(--fontColor1);
    background-color: var(--grayColor1);
  }
}
</style>
