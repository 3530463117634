<template>
  <ValidationProvider
    tag="fieldset"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors }"
    class="scale"
  >
  <fieldset class="scale" :class="{ 'scale--invalid': errors.length }">
    <legend class="scale__label">
      {{ label }}
    </legend>

    <div class="scale__scale">
      <scale-item
        v-for="n in 11"
        :key="n"
        :label="n - 1"
        :value="n - 1"
        :picked="picked"
        @change="$emit('change', $event)"
      />
    </div>

    <span v-if="errors.length" class="error">
      {{ errors[0] }}
    </span>
  </fieldset>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import ScaleItem from './ScaleItem.vue';

export default {
  name: 'Scale',

  components: { ScaleItem, ValidationProvider },

  model: {
    event: 'change',
    prop: 'picked',
  },

  props: {
    label: {
      required: true,
      type: String,
    },

    picked: {
      required: true,
      type: Number,
    },

    rules: {
      default: '',
      type: [String, Object],
    },

    name: {
      type: String,
      default: '',
    },
  },

  methods: {
    isChecked(n) {
      return n === this.picked;
    },

    onChange(e) {
      this.$emit('change', parseInt(e.target.value, 10));
    },
  },
};
</script>

<style lang="scss" scoped>
.scale__label {
  margin-bottom: var(--lengthLg1);
  font-size: var(--lengthMd2);
  font-weight: var(--medium);
  color: var(--fontColor1);
  background-color: white;

  @media only screen and (min-width: 768px) {
    width: fit-content;
    margin: 0 auto var(--lengthLg1);
    font-size: var(--lengthMd3);
    text-align: center;
  }
}

.scale__scale {
  width: fit-content;
  display: flex;
  margin: 0 auto;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.scale--invalid .scale__label {
  color: var(--dangerColor);
}

.scale--invalid .scale__scale {
  border-color: var(--dangerColor);
}
</style>
