<template>
  <div>
    <custom-button
      type="button"
      variant="secondary"
      @click="onLogout"
    >
      <span>Cerrar Sesión</span>
      <font-awesome-icon icon="sign-out-alt" slot="append-icon" size="lg" />
    </custom-button>

    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ConfirmDialogue from './ConfirmDialogue.vue';

import CustomButton from './CustomButton.vue';

export default {
  name: 'LogoutButton',

  components: { ConfirmDialogue, CustomButton },

  computed: {
    ...mapState(['madeChanges']),
  },

  methods: {
    ...mapActions(['logout']),

    async onLogout() {
      if (this.madeChanges) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Guarda tu Progreso',
          message: 'Debes guardar tu progreso antes de salir. Si no lo guardas, perderás tus cambios.',
          isDestructive: true,
          okButton: 'Salir de todas formas',
        });

        if (ok) this.logout();
      } else this.logout();
    },
  },
};
</script>

<style scoped>
span {
  display: none;
}

@media only screen and (min-width: 768px) {
  span {
    display: inline-block;
  }
}
</style>
