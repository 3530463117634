import { render, staticRenderFns } from "./LogoutButton.vue?vue&type=template&id=51d7ead1&scoped=true&"
import script from "./LogoutButton.vue?vue&type=script&lang=js&"
export * from "./LogoutButton.vue?vue&type=script&lang=js&"
import style0 from "./LogoutButton.vue?vue&type=style&index=0&id=51d7ead1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d7ead1",
  null
  
)

export default component.exports