<template>
  <ValidationProvider
    tag="fieldset"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors }"
    class="radio-group"
  >
    <legend v-if="label" class="label" :class="{ 'label--invalid': errors.length }">
      {{ label || name }}
    </legend>

    <div class="options">
      <radio-button
        v-for="(option, index) in options"
        :key="index"
        :name="groupName"
        :option="option"
        :picked="picked"
        @change="$emit('change', $event)"
      />
    </div>

    <span v-if="errors.length" class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import RadioButton from './RadioButton.vue';

export default {
  name: 'RadioButtonGroup',

  model: {
    prop: 'picked',
    event: 'change',
  },

  components: { RadioButton, ValidationProvider },

  props: {
    options: Array,

    picked: [Object, String],

    rules: {
      default: '',
      type: [String, Object],
    },

    name: {
      type: String,
      default: '',
    },

    groupName: String,
    label: String,
  },
};
</script>

<style lang="scss" scoped>
.options {
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);

  @media only screen and (min-width: 768px) {
    justify-content: center;
  }
}

.label {
  margin-bottom: var(--lengthLg1);
  font-size: var(--lengthMd2);
  font-weight: var(--medium);
  color: var(--fontColor1);
  background-color: white;

  @media only screen and (min-width: 768px) {
    width: fit-content;
    margin: 0 auto var(--lengthLg1);
    font-size: var(--lengthMd3);
    text-align: center;
  }
}
</style>
