<template>
  <custom-button
    type="button"
    variant="secondary"
    :disabled="!madeChanges || loading"
    @click="onSave"
  >
    <template v-if="!loading">
      <span>Guardar</span>
      <font-awesome-icon icon="save" slot="append-icon" size="lg" />
    </template>
    <template v-else>
      <span>Guardando</span>
      <loading-spinner slot="append-icon" size="sm" />
    </template>
  </custom-button>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import CustomButton from './CustomButton.vue';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'SaveProgressButton',

  components: { CustomButton, LoadingSpinner },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['madeChanges']),
  },

  methods: {
    ...mapActions(['saveProgress']),

    ...mapMutations(['setToast']),

    async onSave() {
      this.loading = true;

      await this.saveProgress();

      this.loading = false;

      this.setToast('Progreso guardado');
    },
  },
};
</script>

<style scoped>
span {
  display: none;
}

@media only screen and (min-width: 768px) {
  span {
    display: inline-block;
  }
}
</style>
