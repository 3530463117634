<template>
  <label
    class="radio"
    :class="{ 'radio--checked': isChecked }"
  >
    <input
      type="radio"
      name="enps-scale"
      class="radio__input"
      :value="value"
      :checked="isChecked"
      @change="onChange"
    />

    <span class="radio__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'ScaleItem',

  model: {
    event: 'change',
    prop: 'picked',
  },

  props: {
    label: {
      required: true,
      type: Number,
    },

    picked: {
      required: true,
      type: Number,
    },

    value: {
      required: true,
      type: Number,
    },
  },

  computed: {
    isChecked() {
      return this.value === this.picked;
    },
  },

  methods: {
    onChange(e) {
      this.$emit('change', parseInt(e.target.value, 10));
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  padding: var(--lengthSm2);
  background-color: var(--grayColor1);
  cursor: pointer;

  @media only screen and (min-width: 480px) {
    padding: var(--lengthSm3) var(--lengthMd3);
  }

  & + & {
    border-left: 1px solid var(--grayColor2);
  }

  &:first-child {
    border-top-left-radius: var(--lengthSm1);
    border-bottom-left-radius: var(--lengthSm1);
  }

  &:last-child {
    border-right-width: 1px;
    border-top-right-radius: var(--lengthSm1);
    border-bottom-right-radius: var(--lengthSm1);
  }

  &--checked {
    background-color: var(--mainColor1);
    border-color: var(--mainColor1);
  }

  &--checked &__label {
    color: white;
  }

  &__label {
    color: var(--fontColor2);
    font-size: var(--lengthMd2);
    font-weight: var(--medium);
  }

  &__input {
    display: none;
  }
}
</style>
