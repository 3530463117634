<template>
  <label class="radio" :class="{ 'radio--checked': isChecked }">
    <input
      type="radio"
      :value="option"
      :checked="isChecked"
      :name="name"
      @change="onChange(option)"
      class="radio__input"
      tabindex="0"
    />

    <div class="radio__radio">
      <font-awesome-icon icon="check" size="xs" class="radio__check" />
    </div>

    {{ option instanceof Object ? option.text : option }}
  </label>
</template>

<script>
export default {
  name: 'RadioButton',

  model: {
    prop: 'picked',
    event: 'change',
  },

  props: {
    option: [Object, String],
    name: String,
    picked: [Object, String],
  },

  computed: {
    isChecked() {
      return (this.option instanceof Object)
        ? this.option.id === this.picked.id
        : this.option === this.picked;
    },
  },

  methods: {
    onChange(option) {
      this.$emit('change', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  padding: var(--lengthMd1);
  font-weight: var(--medium);
  background-color: white;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
  cursor: pointer;
  transition: background-color 150ms;

  @media only screen and (min-width: 768px) {
    flex-basis: 45%;
  }

  &__radio {
    width: var(--lengthMd3);
    height: var(--lengthMd3);
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--lengthSm2);
    background-color: var(--grayColor1);
    border-radius: 50%;
    transition: background-color 150ms;
  }

  &__check {
    color: var(--grayColor1);
  }

  &:hover:not(&--checked) &__check {
    color: var(--grayColor3);
  }

  &:hover &__radio,
  &__radio:focus {
    border-color: var(--mainColor1);
    outline: none;
  }

  &__input {
    display: none;
  }

  &:hover {
    border-color: var(--mainColor1);
  }

  &--checked {
    color: white;
    background-color: var(--mainColor1);
    border-color: var(--mainColor1);
  }

  &--checked &__check {
    color: var(--mainColor1);
  }

  &--checked &__radio {
    background-color: white;
  }
}
</style>
