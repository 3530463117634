<template>
  <modal ref="modal">
    <template slot="title">{{ title }}</template>

    <template slot="description">{{ message }}</template>

    <div class="btn-container">
      <custom-button
        type="button"
        :variant="isDestructive ? 'primary' : 'secondary'"
        @click="cancel"
      >
        {{ cancelButton }}
      </custom-button>
      <custom-button
        type="button"
        :variant="isDestructive ? 'secondary' : 'primary'"
        @click="confirm"
      >
        {{ okButton }}
      </custom-button>
    </div>
  </modal>
</template>

<script>
import CustomButton from './CustomButton.vue';
import Modal from './Modal.vue';

export default {
  name: 'ConfirmDialogue',

  components: { CustomButton, Modal },

  data: () => ({
    title: '',
    message: '',
    isDestructive: false,
    okButton: '',
    cancelButton: 'Volver',

    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.isDestructive = opts.isDestructive;
      this.okButton = opts.okButton;
      if (opts.cancelButton) this.cancelButton = opts.cancelButton;

      this.$refs.modal.open();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.$refs.modal.close();
      this.resolvePromise(true);
    },

    cancel() {
      this.$refs.modal.close();
      this.resolvePromise(false);
    },
  },
};
</script>

<style scoped>
.btn-container {
  justify-content: flex-end;
  margin-top: var(--lengthMd2);
}
</style>
