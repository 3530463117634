<template>
  <ValidationProvider
    tag="label"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors }"
    class="textarea"
    :vid="vid"
  >
    <h2 v-if="label" class="textarea__label">
      {{ label || name }}
    </h2>

    <textarea
      :value="value"
      :placeholder="placeholder"
      rows="10"
      class="textarea__textarea"
      :class="{ 'textarea__textarea--invalid': errors.length }"
      @input="$emit('input', $event.target.value)"
    >
    </textarea>

    <span class="textarea__errors">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'CustomTextarea',

  components: { ValidationProvider },

  props: {
    value: {
      required: true,
      type: String,
    },

    rules: {
      default: '',
      type: [String, Object],
    },

    name: {
      default: '',
      type: String,
    },

    vid: String,
    label: String,
    placeholder: String,
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  display: block;

  &__label {
    margin-bottom: var(--lengthLg1);
    font-size: var(--lengthMd2);
    font-weight: var(--medium);
    color: var(--fontColor1);
    background-color: white;

    @media only screen and (min-width: 768px) {
      width: fit-content;
      margin: 0 auto var(--lengthLg1);
      font-size: var(--lengthMd3);
      text-align: center;
    }
  }

  &__textarea {
    display: block;
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    padding: var(--lengthMd1) var(--lengthSm2) var(--lengthSm3);
    color: var(--fontColor1);
    border: 1px solid var(--grayColor3);
    border-radius: var(--lengthSm1);
    transition: border-color 300ms;

    &:not(&--invalid):focus {
      border-color: var(--mainColor1);
    }

    &--invalid {
      border-collapse: var(--dangerColor);
    }
  }

  &__errors {
    margin-top: var(--lengthSm1);
    font-size: .75rem;
  }
}
</style>
